import React from 'react'
import styles from './MarkdownHtmlContent.module.scss'

export default function MarkdownHtmlContent({ content }) {
  return (
    <div
      className={styles.content}
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  )
}
