import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO/SEO'
import PageTitle from '../components/PageTitle/PageTitle'
import MarkdownHtmlContent from '../components/MarkdownHtmlContent/MarkdownHtmlContent'

export default function InfosPage({ data }) {
  const html = data.file.childMarkdownRemark.html
  return (
    <>
      <SEO title="Infos" />
      <PageTitle title="Infos" />
      <MarkdownHtmlContent content={html} />
    </>
  )
}

export const infosPageQuery = graphql`
  query {
    file(relativePath: { eq: "settings/infos.md" }) {
      childMarkdownRemark {
        html
      }
    }
  }
`
